/* main_s1 */
.main_s1 {
  width: 100%;
  height: 46.7vw;
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
}
.main_s1 .img_box {
  width: 12.02%;
  margin-top: 8.84vw;
  margin-left: 9.89vw;
}
.main_s1 .img_box img {
  width: 100%;
}
.main_s1 .text_box {
  width: 65.6%;
  height: 77.31%;
  margin-top: 8.84vw;
  margin-right: 3.37vw;
}
.main_s1 .text_box > p {
  font-size: 1.87vw;
  line-height: 2.34vw;
}
.main_s1 .text_box > p:nth-child(1) {
  font-weight: 600;
}
.main_s1 .text_box > p:nth-child(2) {
  margin-bottom: 2vw;
}
.main_s1 .text_box p span {
  font-weight: 600;
}
.main_s1 .text_box .p_box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 1vw;
}
.main_s1 .text_box .p_box p {
  font-size: 1.3vw;
}
.main_s1 .text_box .p_box p:nth-child(1) {
  width: 10%;
}
.main_s1 .text_box .p_box p:nth-child(2) {
  width: 90%;
}
.main_s1 .text_box .p_box p:nth-child(2) span {
  font-size: 1vw;
  color: black;
}
/* main_s2 */
.main_s2 {
  width: 100%;
  height: 25.58vw;
  display: flex;
  align-items: flex-end;
}
.main_s2 .article_container {
  width: 100%;
  height: 85.36%;
  margin-top: 14.64%;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
}
.main_s2 .article_container > p {
  font-size: 4.79vw;
  font-weight: 600;
  color: #c7c7c7;
  margin-left: 11.39vw;
}
.main_s2 .article_container .img_box {
  width: 0.67%;
  margin-left: 8.13vw;
}
.main_s2 .article_container .img_box img {
  width: 100%;
}
.main_s2 .article_container .text_box {
  width: 50%;
  height: 30.19%;
  margin-left: 7.98vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main_s2 .article_container .text_box .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_s2 .article_container .text_box .box p {
  font-size: 1.87vw;
  font-weight: 600;
}
.main_s2 .article_container .text_box .box p:nth-child(2) {
  width: 85%;
}

/* main_s3 */
.main_s3 {
  width: 100%;
  height: 20vw;
  margin-top: 3.5vw;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_s3 .article_container {
  width: 82%;
  display: flex;
  align-items: center;
}
.main_s3 .article_container > p:nth-child(1) {
  width: 25%;
  font-size: 4vw;
  font-weight: 600;
  text-align: center;
  color: #c7c7c7;
}
.main_s3 .article_container .text_box {
  width: 65%;
  margin-left: 10%;
}
.main_s3 .article_container .text_box > p {
  font-size: 1.87vw;
  font-weight: 600;
}
.main_s3 .article_container .text_box > p:nth-child(1) {
  margin-bottom: 1vw;
  line-height: 3vw;
}
.main_s3 .article_container .text_box > p:nth-child(2) {
  font-weight: 400;
}
