/* individualInfo_s1 */
.individualInfo_s1 {
  width: 37.15%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
}
.individualInfo_s1 form > p {
  font-size: 1.5vw;
  font-weight: 700;
  color: #787160;
  text-align: left;
  margin-top: 3.11vw;
  margin-bottom: 1.46vw;
}
.individualInfo_s1 form > p:nth-child(1) {
  margin-top: 0vw;
}
.individualInfo_s1 form .input_box {
  width: 100%;
  height: 3vw;
  display: flex;
  margin-top: 1.46vw;
  align-items: center;
}
.individualInfo_s1 form .input_nomargin {
  margin-top: 0vw;
}
.individualInfo_s1 form > .input_box > p {
  width: 32.63%;
  /* height: 100%; */
  display: flex;
  /* align-items: center; */
  font-size: 1.5vw;
  font-weight: 700;
}
.individualInfo_s1 form > .input_box > p > span {
  font-size: 0.9vw;
  font-weight: 700;
  color: #b6b6b6;
  display: flex;
  align-items: flex-end;
}
.individualInfo_s1 form > .input_box > input {
  width: 67.37%;
  height: 100%;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  padding-left: 1vw;
}
.individualInfo_s1 form > .input_box:nth-child(2) .email_check {
  width: 12.26%;
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  font-size: 0.97vw;
  font-weight: 600;
  color: white;
  border-radius: 0.4vw;
  cursor: pointer;
}
.individualInfo_s1 form > .input_box > .text_id {
  width: 67.37%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1vw;
  font-size: 0.97vw;
  font-weight: 600;
  background-color: #eeeeee;
  border-radius: 0.4vw;
}
.individualInfo_s1 form > .input_box > .pw_btn {
  width: 67.37%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.97vw;
  font-weight: 600;
  background-color: #eeeeee;
  border-radius: 0.4vw;
  cursor: pointer;
}
.individualInfo_s1 form > .input_box > .pw_btn1_box {
  width: 67.37%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.individualInfo_s1 form > .input_box > .pw_btn1_box > .pw_btn1 {
  width: 48%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.97vw;
  font-weight: 600;
  background-color: #eeeeee;
  border-radius: 0.4vw;
  cursor: pointer;
}

.individualInfo_s1 form button {
  width: 7.49vw;
  height: 3vw;
  margin: 0 auto;
  margin-top: 6.07vw;
  border-radius: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.97vw;
  font-weight: 700;
  color: white;
  cursor: pointer;
  background-color: #cabd99;
}
