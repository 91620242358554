@media (max-width: 500px) {
  .square_s1 {
    height: 109.33vw;
  }
  .square_s1 .title_box {
    height: 8.53%;
  }
  .square_s1 .title_box .title {
    width: 22.54%;
  }
  .square_s1 .title_box .title img {
    width: 3.89vw;
    height: 3.89vw;
  }
  .square_s1 .title_box .title p {
    font-size: 3.11vw;
  }
  .square_s1 .article_container {
    height: 91.47%;
  }
  .square_s1 .article_container .article {
    width: 88.08%;
    height: 74.87%;
  }
  .square_s1 .article_container .article > p {
    font-size: 4.15vw;
  }
  .square_s1 .article_container .article .row_container {
    width: 100%;
    height: 85.12%;
  }
  .square_s1 .article_container .article .row_container .row_box {
    height: 45.93%;
  }
  .square_s1
    .article_container
    .article
    .row_container
    .row_box
    .row
    p:nth-child(2) {
    font-size: 2.85vw;
  }
  .square_s1
    .article_container
    .article
    .row_container
    .row_box
    .row
    p:nth-child(3) {
    font-size: 2.59vw;
  }
}
