/* showCompany */
.showCompany {
  width: 65vw;
  height: 33.39vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  justify-content: center;
  box-shadow: 7px 7px 28px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;
}
.showCompany .article_container {
  width: 95%;
  margin: 0;
  margin-top: 2vw;
  margin-bottom: 2vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showCompany .article_container img {
  width: 1.18vw;
  margin-left: auto;
  cursor: pointer;
}
.showCompany .article_container .article {
  width: 90%;
  height: 90%;
}
.showCompany .article_container .article > p:nth-child(1) {
  font-size: 1.09vw;
  font-weight: 700;
}
.showCompany .article_container .article > p:nth-child(2) {
  font-size: 0.83vw;
  font-weight: 400;
  margin-top: 3.33vw;
}
.showCompany .article_container .article > p:nth-child(3) {
  font-size: 0.83vw;
  font-weight: 400;
  margin-top: 1.41vw;
}
.showCompany .article_container .article .list_box {
  width: 100%;
  height: 4.38vw;
  margin-top: 1.41vw;
}
.showCompany .article_container .article .list_box .row {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showCompany .article_container .article .list_box .row p {
  text-align: center;
}
.showCompany .article_container .article .list_box .row p:nth-child(1) {
  width: 20%;
}
.showCompany .article_container .article .list_box .row p:nth-child(2) {
  width: 20%;
}
.showCompany .article_container .article .list_box .row p:nth-child(3) {
  width: 15%;
}
.showCompany .article_container .article .list_box .row p:nth-child(4) {
  width: 18%;
}
.showCompany .article_container .article .list_box .row p:nth-child(5) {
  width: 12%;
}
.showCompany .article_container .article .list_box .row p:nth-child(6) {
  width: 7%;
}
.showCompany .article_container .article > p:nth-child(5) {
  font-size: 0.83vw;
  font-weight: 400;
  margin-top: 3vw;
}
.showCompany .article_container .article > p:nth-child(6) {
  font-size: 0.83vw;
  font-weight: 400;
  margin-top: 1.6vw;
}
.showCompany .article_container .article .btn_box {
  width: 30%;
  height: 9%;
  margin: 0 auto;
  margin-top: 1.8vw;
  display: flex;
  justify-content: space-between;
}
.showCompany .article_container .article .btn_box .btn {
  width: 46%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.83vw;
  color: white;
  border-radius: 0.4vw;
  cursor: pointer;
}
.showCompany .article_container .article .btn_box .btn:nth-child(1) {
  background-color: #b6b6b6;
}
.showCompany .article_container .article .btn_box .btn:nth-child(2) {
  background-color: #cabd99;
}
