@media (max-width: 500px) {
  /* matchingInfo_s1 */
  .matchingInfo_s1 {
    height: 62.44vw;
    display: flex;
    align-items: center;
  }
  .matchingInfo_s1 .section_container {
    width: 100%;
    height: 84.65%;
    margin: 0;
  }
  .matchingInfo_s1 .section_container > p {
    font-size: 2.59vw;
    padding-left: 5.44vw;
    color: #979797;
  }
  .matchingInfo_s1 .section_container .container1 .article p {
    font-size: 2.59vw;
  }
  .matchingInfo_s1 .section_container .container2 .article {
    flex-direction: column;
  }
  .matchingInfo_s1 .section_container .container2 .article p {
    font-size: 2.59vw;
  }
  .matchingInfo_s1 .section_container .container2 .article p:nth-child(2) {
    font-size: 3.11vw;
  }
  .matchingInfo_s1 .section_container .container2 .article p:nth-child(2) span {
    font-size: 5.18vw;
    margin-left: 0;
  }
  /* matchingInfo_s2 */
  .matching_s2 {
    height: 25.13vw;
  }
  .matching_s2 .section_container {
    width: 100%;
  }
  .matching_s2 .section_container .article_container {
    width: 88.86%;
    height: 87.63%;
  }
  .matching_s2 .section_container .article_container .article {
    height: 24%;
  }
  .matching_s2 .section_container .article_container .article p {
    font-size: 3.37vw;
  }
  .matching_s2
    .section_container
    .article_container
    .article
    .select_container {
    width: 88.05%;
  }
  .matching_s2 select {
    font-size: 2.59vw;
  }
  /* matchingInfo_s3 */
  .matching_s3 {
    height: 103.89vw;
  }
  .matching_s3 .section_container {
    width: 88.86%;
    height: 76.31%;
    margin-top: 5.96vw;
  }
  .matching_s3 .section_container .box_container {
    height: 21.57%;
    flex-direction: column;
    align-items: flex-start;
  }
  .matching_s3 .section_container .box_container .select_box {
    width: 100%;
    height: 48.48%;
  }
  .matching_s3 .section_container .box_container > p {
    font-size: 5.18vw;
  }
  .matching_s3 select {
    font-size: 3.11vw;
  }
  .matching_s3 .section_container .table_container {
    height: 72.22%;
    margin-top: 4.8vw;
  }
  .matching_s3 .section_container .table_container p {
    font-size: 2.59vw !important;
  }
  .matching_s3 .section_container .table_container .row:nth-child(1) p {
    font-size: 3.12vw !important;
  }
  .matching_s3 .section_container .table_container .head_row {
    height: 17.65% !important;
  }
  .matching_s3 .section_container .table_container .row {
    height: 16.47%;
  }
  .matching_s3 .section_container .table_container .row p:nth-child(1) {
    margin-left: 0.2vw;
  }
  .matching_s3 .section_container .table_container .row p:nth-child(2) {
    margin-left: 3vw;
    width: 40%;
  }
  .matching_s3 .section_container .table_container .row p:nth-child(3) {
    margin-left: 4vw;
    width: 15%;
    line-height: 1.5vw;
  }
  .matching_s3 .section_container .table_container .row p:nth-child(4) {
    margin-left: 4vw;
    width: 19%;
    line-height: 1.5vw;
  }
}
