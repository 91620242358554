.footer {
  width: 100%;
  height: 8.73vw;
  display: flex;
  align-items: center;
}
.footer .article_container {
  width: 80%;
  height: 73.82%;
  margin-left: 4.79vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer .article_container img {
  width: 4.38vw;
  cursor: pointer;
}
.footer .article_container p {
  font-size: 0.9vw;
  color: #b6b6b6;
}
