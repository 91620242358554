@media (max-width: 500px) {
  /* .myPageBusiness_s1 */
  .myPageBusiness_s1 {
    height: 86.27vw;
  }
  .myPageBusiness_s1 .title_box {
    height: 10.51%;
  }
  .myPageBusiness_s1 .title_box .title {
    width: 19.43%;
  }
  .myPageBusiness_s1 .title_box .title img {
    width: 3.89vw;
    height: 3.89vw;
  }
  .myPageBusiness_s1 .title_box .title p {
    font-size: 3.11vw;
  }
  .myPageBusiness_s1 .article_container {
    height: 89.49%;
  }
  .myPageBusiness_s1 .article_container .article {
    width: 62.7%;
    height: 70%;
    margin-top: 10vw;
  }
  .myPageBusiness_s1 .article_container .article p:nth-child(2) {
    font-size: 6.22vw;
  }
  .myPageBusiness_s1 .article_container .article p:nth-child(3) {
    font-size: 4.15vw;
  }
  .myPageBusiness_s1 .article_container .article p:nth-child(4) {
    font-size: 3.11vw;
  }

  /* myPageBusiness_s2 */
  .myPageBusiness_s2 {
    height: 72.8vw;
  }
  .myPageBusiness_s2 .article_container {
    width: 100%;
  }
  .myPageBusiness_s2 .article_container .box_container p {
    font-size: 5.18vw;
    margin-left: 4vw;
  }
  .myPageBusiness_s2 .article_container .box_container .btn_box {
    width: 23%;
    font-size: 2.59vw;
  }
  .myPageBusiness_s2 .article_container .table_container {
    height: 73.31%;
  }

  .myPageBusiness_s2 .article_container .table_container .row p:nth-child(2) {
    font-size: 2.59vw;
  }
  .myPageBusiness_s2 .article_container .table_container .row:nth-child(1) p {
    font-size: 3.11vw;
  }
  .myPageBusiness_s2 .article_container .table_container .content_row p {
    font-size: 2.59vw;
  }
  .myPageBusiness_s2
    .article_container
    .table_container
    .row
    .manage_box
    .manage {
    font-size: 2.59vw;
  }
  .myPageBusiness_s2 .article_container .table_container .row p:nth-child(2) {
    margin-left: 2vw;
    width: 30%;
  }
  .myPageBusiness_s2 .article_container .table_container .row p:nth-child(3) {
    margin-left: 3vw;
    width: 20%;
  }
  .myPageBusiness_s2 .article_container .table_container .row p:nth-child(4) {
    width: 16%;
  }
  .myPageBusiness_s2 .article_container .table_container .row p:nth-child(5) {
    width: 16%;
  }
  .myPageBusiness_s2 .article_container .table_container .row .manage_box {
    width: 16%;
  }
  .myPageBusiness_s2
    .article_container
    .table_container
    .row
    .manage_box
    .manage {
    height: 52%;
  }

  /* myPageBusiness_s3 */
  .myPageBusiness_s3 {
    height: 72.8vw;
  }
  .myPageBusiness_s3 .article_container {
    width: 100%;
  }
  .myPageBusiness_s3 .article_container > p {
    font-size: 5.18vw;
    margin-left: 4vw;
  }
  .myPageBusiness_s3 .article_container .box_container .btn_box {
    width: 23%;
    font-size: 2.59vw;
  }
  .myPageBusiness_s3 .article_container .table_container {
    height: 73.31%;
  }
  .myPageBusiness_s3 .article_container .table_container .row p:nth-child(2) {
    font-size: 2.59vw;
  }
  .myPageBusiness_s3 .article_container .table_container .row:nth-child(1) p {
    font-size: 3.11vw;
  }
  .myPageBusiness_s3 .article_container .table_container .content_row p {
    font-size: 2.59vw;
  }
  .myPageBusiness_s3
    .article_container
    .table_container
    .row
    .manage_box
    .manage {
    font-size: 2.59vw;
  }
  .myPageBusiness_s3 .article_container .table_container .row p:nth-child(2) {
    margin-left: 2vw;
    width: 35%;
  }
  .myPageBusiness_s3 .article_container .table_container .row p:nth-child(3) {
    margin-left: 3vw;
    width: 20%;
  }
  .myPageBusiness_s3 .article_container .table_container .row p:nth-child(4) {
    width: 16%;
    letter-spacing: -0.02vw;
    line-height: 2vw;
  }
  .myPageBusiness_s3 .article_container .table_container .row p:nth-child(5) {
    width: 16%;
  }
  .myPageBusiness_s3 .article_container .table_container .row .manage_box {
    width: 16%;
  }
  .myPageBusiness_s3
    .article_container
    .table_container
    .row
    .manage_box
    .manage {
    height: 52%;
  }
  .myPageBusiness_s3 .article_container .table_container .row div:nth-child(5) {
    width: 16%;
    font-size: 2.59vw;
  }
  .myPageBusiness_s3 .article_container .table_container .row .btn_box .btn {
    font-size: 2.59vw;
  }
}
