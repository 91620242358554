/* eduDetail_s1 */
.eduDetail_s1 {
  width: 100%;
  height: 74.04vw;
}
.eduDetail_s1 .title_box {
  width: 100%;
  height: 8.85%;
  display: flex;
  align-items: center;
}
.eduDetail_s1 .title_box .title {
  width: 22.33%;
  height: 26.86%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4.79vw;
}
.eduDetail_s1 .title_box .title img {
  width: 1.12vw;
  height: 1.12vw;
}
.eduDetail_s1 .title_box .title p {
  font-size: 1.35vw;
  font-weight: 700;
  color: #272727;
}
.eduDetail_s1 .article_container {
  width: 100%;
  height: 91.15%;
  background-color: #f8f8f8;
}
.eduDetail_s1 .article_container .article {
  width: 52.85%;
  height: auto;
  margin: 0 auto;
}
.eduDetail_s1 .article_container .article img {
  width: 100%;
  margin-top: 3.78vw;
}
.eduDetail_s1 .article_container .article p:nth-child(2) {
  font-size: 1.8vw;
  font-weight: 700;
  color: #272727;
  margin-top: 4.31vw;
}
.eduDetail_s1 .article_container .article p:nth-child(3) {
  font-size: 0.75vw;
  color: #b6b6b6;
}
.eduDetail_s1 .article_container .article p:nth-child(4) {
  font-size: 0.9vw;
  font-weight: 700;
  color: #272727;
  display: inline-block;
  padding-bottom: 0.3vw;
  border-bottom: 2px solid #b6b6b6;
  margin-top: 2.88vw;
}
.eduDetail_s1 .article_container .article .author {
  width: 100%;
  height: 2.06vw;
  margin-top: 1.99vw;
}
.eduDetail_s1 .article_container .article .author .box {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
}
.eduDetail_s1 .article_container .article .author .box img {
  width: 0.56vw;
  height: 0.56vw;
  margin: 0;
}
.eduDetail_s1 .article_container .article .author .box p {
  font-size: 0.75vw;
  color: #b6b6b6;
  font-weight: 400;
  margin: 0;
  margin-left: 0.45vw;
}
.eduDetail_s1 .article_container .article p:nth-child(6) {
  font-size: 0.9vw;
  color: #272727;
  margin-top: 2.81vw;
}
/* eduDetail_s2 */
.eduDetail_s2 {
  width: 100%;
  height: 22.85vw;
}
.eduDetail_s2 .article_container {
  width: 52.85%;
  height: 75.08%;
  margin: 0 auto;
  margin-top: 2.4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.eduDetail_s2 .article_container .title_box {
  width: 100%;
  height: 9.61%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.eduDetail_s2 .article_container .title_box p:nth-child(1) {
  font-size: 1.35vw;
  font-weight: 700;
  color: #272727;
}
.eduDetail_s2 .article_container .title_box p:nth-child(2) {
  font-size: 0.75vw;
  color: #b6b6b6;
  cursor: pointer;
}
.eduDetail_s2 .article_container .article_box {
  width: 100%;
  height: 75.76%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.eduDetail_s2 .article_container .article_box .article {
  width: 30.32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.eduDetail_s2 .article_container .article_box .article img {
  width: 100%;
  height: 78.84%;
}
.eduDetail_s2 .article_container .article_box .article p:nth-child(2) {
  font-size: 0.9vw;
  font-weight: 700;
  color: #272727;
}
.eduDetail_s2 .article_container .article_box .article p:nth-child(3) {
  font-size: 0.45vw;
  font-weight: 500;
  color: #b6b6b6;
}
