/* pay */
.pay {
  width: 30vw;
  height: auto;
  /* overflow: scroll; */
  /* height: 39.48vw; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  justify-content: center;
  box-shadow: 7px 7px 28px 4px rgba(0, 0, 0, 0.1);
  z-index: 4;
}
.pay .article_container {
  width: 95%;
  margin: 0;
  margin-top: 2vw;
  margin-bottom: 2vw;
  height: auto;
}
.pay .article_container > img {
  margin-left: auto;
  width: 1.2vw;
  cursor: pointer;
}
.pay .article_container p {
  font-size: 1.2vw;
  text-align: center;
}
.pay .article_container .btn {
  width: 50%;
  height: 3vw;
  font-size: 1vw;
  margin-top: 1vw;
}
