@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: url(Inter/static/Inter-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url(Inter/static/Inter-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(Inter/static/Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url(Inter/static/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url(Inter/static/Inter-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url(Inter/static/Inter-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url(Inter/static/Inter-Black.ttf) format("truetype");
}
