/* showUserDetail */
.showUserDetail {
  width: 50vw;
  height: 80vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 7px 7px 28px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;
}
.showUserDetail > img {
  margin-top: 1vw;
  margin-left: auto;
  margin-right: 1vw;
  width: 1.2vw;
  cursor: pointer;
}
.showUserDetail .print_btn {
  width: 1.2vw;
  position: absolute;
  bottom: 4vw;
  right: 2vw;
  z-index: 5;
}
.showUserDetail .article_container {
  width: 95%;
  height: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.showUserDetail .article_container > p {
  font-size: 1.09vw;
  margin-bottom: 1vw;
}
.showUserDetail .article_container > .title {
  font-size: 1.09vw !important;
  font-weight: 700;
  color: #cabd99;
  margin-top: 2vw !important;
  text-align: center;
}
.showUserDetail .article_container .text_box {
  width: 80%;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 1vw;
}
.showUserDetail .article_container .text_box:last-child {
  margin-bottom: 3vw;
}
.showUserDetail .article_container .text_box p {
  font-size: 0.9vw;
}
.showUserDetail .article_container .text_box p:nth-child(1) {
  width: 30%;
  text-align: center;
}
.showUserDetail .article_container .text_box p:nth-child(2) {
  width: 60%;
}

@media print {
  /* showUserDetail */
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .article_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 4vw;
    margin-bottom: 2vw;
  }
  .article_container > p:nth-child(1) {
    font-size: 2.5vw;
  }
  .article_container > .title {
    font-size: 2vw;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
  }
  /* .article_container > .title {
    font-size: 1.09vw !important;
    font-weight: 700;
    color: black !important;
    margin-top: 2vw !important;
    text-align: center;
    border: 1px solid blue;
  } */
  .article_container .text_box {
    width: 80%;
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .article_container .text_box:last-child {
    margin-bottom: 1vw;
  }
  .article_container .text_box p {
    font-size: 1.4vw;
  }
  .article_container .text_box p:nth-child(1) {
    width: 30%;
    text-align: center;
  }
  .article_container .text_box p:nth-child(2) {
    width: 60%;
  }
}
