/* selectPay */
.selectPay {
  width: 25.52vw;
  height: 16.67vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 7px 7px 28px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;
}
.selectPay .article_container {
  width: 90%;
  height: 92%;
  margin: 0;
  justify-content: flex-start;
}
.selectPay .article_container > img {
  margin-left: auto;
  width: 1.2vw;
  cursor: pointer;
}
.selectPay .article_container > p {
  font-size: 3.8vw;
  font-weight: 700;
}
.selectPay .article_container .select_box {
  width: 45%;
  height: 25%;
  margin: 0 auto;
  margin-top: 2.5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.selectPay .article_container .select_box .select {
  width: 100%;
  height: 46%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectPay .article_container .select_box .select img {
  width: 1.04vw;
  cursor: pointer;
}
.selectPay .article_container .select_box .select p {
  font-size: 0.83vw;
}
.selectPay .article_container .select_box .select p:nth-child(2) {
  margin-right: 1vw;
}
.selectPay .article_container .btn {
  width: 5.31vw;
  height: 2.14vw;
  margin: 0 auto;
  margin-top: 3vw;
  font-size: 0.83vw;
  color: white;
  background-color: #cabd99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4vw;
  cursor: pointer;
}
