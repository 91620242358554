/* signin_step3_s1 */
.signin_step3_s1 {
  width: 53.56%;
  height: 15.99vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.signin_step3_s1 .article_container {
  width: 100%;
  height: 24.12%;
  display: flex;
  justify-content: space-between;
}
.signin_step3_s1 .article_container .article {
  width: 26.71%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signin_step3_s1 .article_container .article > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.signin_step3_s1 .article_container .article > p {
  font-size: 1.12vw;
  font-weight: 700;
  color: white;
  position: inherit;
  z-index: 2;
}

/* signin_step3_s2 */
.signin_step3_s2 {
  width: 37.15%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.signin_step3_s2 form > p {
  font-size: 1.5vw;
  font-weight: 700;
  color: #787160;
  text-align: left;
  margin-top: 3.11vw;
  margin-bottom: 1.46vw;
}
.signin_step3_s2 form > p:nth-child(1) {
  margin-top: 0vw;
}
.signin_step3_s2 form .input_box {
  width: 100%;
  height: 3vw;
  display: flex;
  margin-top: 1.46vw;
  align-items: center;
}
.signin_step3_s2 form .input_nomargin {
  margin-top: 0vw;
}
.signin_step3_s2 form > .input_box > p {
  width: 32.63%;
  /* height: 100%; */
  display: flex;
  /* align-items: center; */
  font-size: 1.5vw;
  font-weight: 700;
}
.signin_step3_s2 form > .input_box > p > span {
  font-size: 0.9vw;
  font-weight: 700;
  color: #b6b6b6;
  display: flex;
  align-items: flex-end;
}
.signin_step3_s2 form > .input_box > input {
  width: 67.37%;
  height: 100%;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  padding-left: 1vw;
}
.signin_step3_s2 form > .input_box:nth-child(2) > input {
  width: 53.64%;
  height: 100%;
}
.signin_step3_s2 form > .input_box > .input_btn {
  width: 53.64%;
  height: 100%;
}
.signin_step3_s2 form > .input_box .btn {
  width: 12.26%;
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  font-size: 0.97vw;
  font-weight: 600;
  color: white;
  border-radius: 0.4vw;
  cursor: pointer;
}
.signin_step3_s2 form > .input_box > .sub_input {
  width: 67.37%;
  height: 100%;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signin_step3_s2 form > .input_box > .sub_input > input {
  width: 40%;
  height: 100%;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
}
.signin_step3_s2 form > .input_box > .sub_input1 > input {
  width: 15%;
}
.signin_step3_s2 form .select_box {
  width: 100%;
  height: 3vw;
  display: flex;
  margin-top: 1.46vw;
  align-items: center;
}
.signin_step3_s2 form > .select_box > p {
  width: 32.63%;
  /* height: 100%; */
  display: flex;
  /* align-items: center; */
  font-size: 1.5vw;
  font-weight: 700;
}
.signin_step3_s2 form > .select_box > .sub_select {
  width: 67.37%;
  height: 100%;
  display: flex;
}
.signin_step3_s2 form > .select_box > .sub_select > .select {
  height: 100%;
  font-size: 1.2vw;
  border: none;
  border: 2px solid #eeeeee;
  border-radius: 0.4vw;
  font-size: 0.98vw;
  color: #707070;
  text-align: center;
  margin: 0vw;
  padding: 0vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.signin_step3_s2 form > .select_box > .sub_select > .select {
  width: 7.8vw;
  margin-right: 0.7vw;
}
.signin_step3_s2 form > .select_box > .sub_select > .select:last-child {
  margin-right: 0vw;
}
.signin_step3_s2 form button {
  width: 7.49vw;
  height: 3vw;
  margin: 0 auto;
  margin-top: 6.07vw;
  border-radius: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.97vw;
  font-weight: 700;
  color: white;
  cursor: pointer;
  background-color: #cabd99;
}
