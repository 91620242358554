.nav {
  width: 100%;
  height: 4.72vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 4.95vw;
}
.nav ul li {
  font-size: 1.35vw;
  font-weight: 600;
  color: #272727;
  margin-right: 3.02vw;
  cursor: pointer;
}
.nav ul:nth-child(2) li {
  color: #979797;
}
