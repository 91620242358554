/* acceptPopup */
.acceptPopup {
  width: 32vw;
  height: 20vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 7px 7px 28px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;
}
.acceptPopup .article_container {
  width: 100%;
  height: 70%;
  margin: 0;
  align-items: center;
  justify-content: space-between;
}
.acceptPopup .article_container p:nth-child(1) {
  font-size: 1.09vw;
  font-weight: 700;
  margin-top: 1vw;
}
.acceptPopup .article_container p:nth-child(2) {
  font-size: 0.9vw;
}
.acceptPopup .article_container .btn_box {
  width: 40%;
  height: 2.5vw;
  display: flex;
  justify-content: space-between;
}
.acceptPopup .article_container .btn_box .btn {
  width: 47%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4vw;
  font-size: 0.78vw;
  color: white;
  background-color: #b6b6b6;
  cursor: pointer;
}
.acceptPopup .article_container .btn_box .btn:nth-child(2) {
  background-color: #cabd99;
}
