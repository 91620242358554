.banner {
  width: 100%;
  height: 20.34vw;
  position: relative;
}
.banner > img {
  width: 100%;
  height: 100%;
}
.banner .dot_box {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0.6vw;
  display: flex;
  justify-content: center;
}
.banner .dot_box img {
  width: 0.8vw;
  margin: 0 0.4vw;
  cursor: pointer;
}

@media (max-width: 500px) {
  .banner {
    height: 80.83vw;
    display: flex;
    align-items: center;
    background-color: #f9f5ea;
  }
  .banner img {
    height: 83.65%;
  }
}
