/* applicationStep1_s1 */
.applicationStep1_s1 {
  width: 53.56%;
  height: 15.99vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.applicationStep1_s1 .article_container {
  width: 100%;
  height: 24.12%;
  display: flex;
  justify-content: space-around;
}
.applicationStep1_s1 .article_container .article {
  width: 26.71%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.applicationStep1_s1 .article_container .article > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.applicationStep1_s1 .article_container .article > p {
  font-size: 1.12vw;
  font-weight: 700;
  color: white;
  position: inherit;
  z-index: 2;
}

/* applicationStep1_s2 */
.applicationStep1_s2 {
  width: 37.15%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 10vw;
}
.applicationStep1_s2 form > p {
  font-size: 1.5vw;
  font-weight: 700;
  color: #787160;
  text-align: left;
  margin-top: 3.11vw;
  margin-bottom: 1.46vw;
}
.applicationStep1_s2 form .title {
  text-align: center;
}
.applicationStep1_s2 form .title_input {
  width: 100%;
  height: 3vw;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  padding-left: 1vw;
}
.applicationStep1_s2 form .input_box {
  width: 100%;
  height: 3vw;
  display: flex;
  margin-top: 1.46vw;
  align-items: center;
}
.applicationStep1_s2 form > .input_box > p {
  width: 32.63%;
  /* height: 100%; */
  display: flex;
  /* align-items: center; */
  font-size: 1.5vw;
  font-weight: 700;
}
.applicationStep1_s2 form > .input_box > input {
  width: 67.37%;
  height: 100%;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  padding-left: 1vw;
}
.applicationStep1_s2 form > .input_box > .sub_input {
  width: 67.37%;
  height: 100%;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.applicationStep1_s2 form > .input_box > .sub_input > input {
  width: 40%;
  height: 100%;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  text-align: center;
}
.applicationStep1_s2 form .select_box {
  width: 100%;
  height: 3vw;
  display: flex;
  margin-top: 1.46vw;
  align-items: center;
}
.applicationStep1_s2 form > .select_box > p {
  width: 32.63%;
  /* height: 100%; */
  display: flex;
  /* align-items: center; */
  font-size: 1.5vw;
  font-weight: 700;
}
.applicationStep1_s2 form > .select_box > .sub_select {
  width: 67.37%;
  height: 100%;
  display: flex;
}
.applicationStep1_s2 form > .select_box > .sub_select > .select {
  height: 100%;
  border: none;
  border: 2px solid #eeeeee;
  border-radius: 0.4vw;
  font-size: 0.98vw;
  color: #707070;
  text-align: center;
  margin: 0vw;
  padding: 0vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.applicationStep1_s2 form > .input_box > .sub_input1 > p {
  width: 65%;
  font-size: 1.5vw;
  font-weight: 700;
}
.applicationStep1_s2 form > .input_box > .sub_input1 > input {
  width: 30%;
}
.applicationStep1_s2 form > .input_box > .sub_input2 > p {
  width: 20%;
  font-size: 1.5vw;
  font-weight: 700;
}
.applicationStep1_s2 form > .input_box > .sub_input2 > input {
  width: 74%;
}
.applicationStep1_s2 form > .select_box > .sub_select1 > .select {
  width: 100%;
}
.applicationStep1_s2 form > .input_box > .sub_input2 > p {
  width: 20%;
  font-size: 1.5vw;
  font-weight: 700;
}
.applicationStep1_s2 form > .input_box > .sub_input2 > input {
  width: 35%;
  margin-right: 0.7vw;
}
.applicationStep1_s2 form > .select_box > .sub_select2 > .select {
  width: 5.8vw;
  margin-right: 0.7vw;
  letter-spacing: -0.08vw;
}
.applicationStep1_s2 form > .select_box > .sub_select2 > .select:last-child {
  margin-right: 0vw;
}
.applicationStep1_s2 form > .select_box > .sub_select3 > .select {
  width: 5.8vw;
  margin-right: 0.7vw;
}
.applicationStep1_s2 form > .select_box > .sub_select3 > .select:last-child {
  width: 11.6vw;
  margin-right: 0vw;
}
.applicationStep1_s2 form > .select_box > .sub_select3 > input {
  width: 74%;
  height: 100%;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  padding-left: 1vw;
}
.applicationStep1_s2 form > .select_box > .sub_select4 > .select {
  width: 49%;
  margin-right: 0.7vw;
}
.applicationStep1_s2 form > .select_box > .sub_select4 > .select:last-child {
  margin-right: 0vw;
}
.applicationStep1_s2 form > .select_box > .sub_select5 {
  align-items: center;
  justify-content: space-between;
}
.applicationStep1_s2 form > .select_box > .sub_select5 > .select {
  width: 5.8vw;
  margin-right: 0.7vw;
}
.applicationStep1_s2 form > .select_box > .sub_select5 > .select:last-child {
  width: 11.6vw;
  margin-right: 0vw;
}
.applicationStep1_s2 form > .select_box > .sub_select5 > input {
  width: 20%;
  height: 100%;
  background-color: #eeeeee;
  font-size: 1.2vw;
  border: none;
  border-radius: 0.4vw;
  text-align: center;
}
.applicationStep1_s2 form > .select_box > .sub_select5 p {
  font-size: 1.5vw;
  font-weight: 700;
}
.applicationStep1_s2 form .article {
  width: 100%;
  height: 11.24vw;
  border: 1px solid #cabd99;
  border-radius: 0.5vw;
  background-color: #f9f5ea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3.75vw;
}
.applicationStep1_s2 form .article > p {
  width: 85%;
}
.applicationStep1_s2 form .article > p:nth-child(1) {
  font-size: 1.12vw;
  font-weight: 700;
  color: #272727;
}
.applicationStep1_s2 form .article > p:nth-child(2) {
  font-size: 0.94vw;
  font-weight: 700;
  color: #cabd99;
  margin-top: 1.31vw;
  letter-spacing: -0.06vw;
  line-height: 2vw;
}
.applicationStep1_s2 form button {
  width: 7.49vw;
  height: 3vw;
  margin: 0 auto;
  margin-top: 6.07vw;
  border-radius: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.97vw;
  font-weight: 700;
  color: white;
  cursor: pointer;
  background-color: #cabd99;
}
