/* showDetail */
.showDetail {
  width: 70vw;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  justify-content: center;
  box-shadow: 7px 7px 28px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;
}
.showDetail .article_container {
  width: 95%;
  margin: 0;
  margin-top: 2vw;
  margin-bottom: 2vw;
  height: auto;
}
.showDetail .article_container p {
  color: black;
}
.showDetail .article_container > img {
  margin-left: auto;
  width: 1.2vw;
  cursor: pointer;
}
.showDetail .article_container > p:nth-child(2) {
  font-size: 1.09vw;
  font-weight: 700;
  margin-top: 0.91vw;
  text-align: center;
}
.showDetail .article_container > p:nth-child(3) {
  font-size: 0.83vw;
  margin-top: 2.86vw;
  margin-left: 0.78vw;
}
.showDetail .article_container .list_box {
  width: 100%;
  height: auto;
  margin-top: 1.09vw;
}
.showDetail .article_container .list_box .row {
  width: 100%;
  height: 2.03vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showDetail .article_container .list_box .row p {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.83vw;
}
.showDetail .article_container .list_box .row p:nth-child(1) {
  width: 4%;
}
.showDetail .article_container .list_box .row p:nth-child(2) {
  width: 10%;
}
.showDetail .article_container .list_box .row p:nth-child(3) {
  width: 20%;
}
.showDetail .article_container .list_box .row p:nth-child(4) {
  width: 10%;
}
.showDetail .article_container .list_box .row p:nth-child(5) {
  width: 25%;
}
.showDetail .article_container .list_box .row_head p:nth-child(6) {
  width: 5vw;
}
.showDetail .article_container .list_box .row_content p:nth-child(6) {
  width: 5vw;
  height: 80%;
  color: white;
  background-color: #cabd99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4vw;
  cursor: pointer;
}

.showDetail .article_container .btn {
  width: 5.31vw;
  height: 2.14vw;
  margin: 0 auto;
  margin-top: 4.17vw;
  font-size: 0.83vw;
  color: white;
  background-color: #cabd99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4vw;
  cursor: pointer;
}
